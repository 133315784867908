.app-navigation-wrapper * {
  font-size: 1.5vw;
}
.app-navigation-wrapper {
  background-color: #E5E4E4;
  padding: 10px 20px;
  margin-top: 1.1em;

  div.navigation-list-wrapper:first-child  {
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: space-between;
  }

  .navigation-list {
    display: flex;
  }

  .navigation-link {
    margin: auto 0 auto 10px;
    padding-right: 10px;
    position: relative;
    display: inline-block;
    color: #000;
    line-height: 1rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      &:after {
        content: none;
      }
    }

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      border-right: 1px solid black;
      height: 1.3rem;
      transform: translateY(-50%);
    }


    .navigation-link-content {
      display: inline-block;
      font-size: 1rem;

      @media screen and (max-width: 950px) {
        font-size: .5rem;
      }
    }
  }

  .cta-wrapper {
    display: inline-block;
    a {
      text-decoration: none;

      .cta-button {
        font-family: TungstenMedium,sans-serif;
        font-size: 1.2rem;
        border: 2px solid black;
        border-radius: 3px;
        padding: 5px 20px;
        color: #000;


        @media screen and (max-width: 950px) {
          font-size: .6rem;
        }
      }
    }
  }
}

.app-wrapper {
  .app-navigation-wrapper + .main-app.block-wrapper {
    margin-top: 0;
  }
}