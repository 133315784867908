@import '../../common/scss/variables';
@import '../../common/scss/mixins';

.prev-status-table {
    width: 100%;
    border-collapse: collapse;

    th,td {
        border: 2px solid $white-color;
        min-width: 16.65%;
    }

    th {
        padding: 9px 5px;
        font-size: 0.87rem;
        text-align: left;
    }

    td {
        padding: 9px 5px;
        font-size: 0.8rem;
        background-color: lighten( $main-color, 1.77% );
    }

    .resolution-badge {
        padding: 5px 15px;
        border: 1px solid;
        font-size: 0.75rem;
        font-weight: bold;
        text-align: center;
        border-radius: 4px;

        .dot {
            display: inline-block;
            border-radius: 50%;
            margin-right: 4px;
            height: 8px;
            width: 8px;
        }

        @include makeTableResolutionBadges();
    }
}