@import "variables";
@import "mixins";

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
  box-sizing: border-box;

  &.container {
    width: #{$grid-size}px;
  }

  .row {
    width: 100%;
  }

  @media screen and (max-width: #{$grid-size + 1}px){
    width: 100% !important;
  }

  > .col {
    flex: 0 0 auto;
    margin-left: $x-margin;
    margin-right: $x-margin;

    &.center {
      margin: 0 auto;
      padding-left: $x-margin; 
      padding-right: $x-margin;
    }

    @include makeColBreakPoints();
  }
}