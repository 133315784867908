@import '../../common/scss/variables';

.submit-entry-btn {
    background-color: $btn-background; 
    display: inline-block;
    border-radius: 4px;
    margin-top: 1em;
    margin-left: auto;
    margin-right: auto;
    padding: 14px;
    color: $dark-color;
    text-transform: uppercase;
    font-size: 1.5em;
    font-family: "TungstenMedium", sans-serif;

    &.disabled {
        cursor: not-allowed;
        opacity: .6;
    }
}