.app-container {
    .loading-wrapper {
        overflow: auto;
    }
}

.notification {
    margin: 4em 0;

    &.initial {
        .notification-img {
            background-image: url(/assets/img/initial.svg);
        }
    }
}

.notification-img {
    height: 13em;
    width: 13em;
    background: center/cover no-repeat;
    margin-bottom: 1em;
    margin-left: auto;
    margin-right: auto;
}

.text-center{
    text-align: center;
}


.app-wrapper {
    border-radius: 8px;
    position: relative;
}

.main-app {
    position: relative;
    margin-bottom: 1.1em;

    &.block-wrapper {
        border-radius: 0;
    }
}

.statuses-sub-section {
    text-align: center;
}
.info-contact-us{
    line-height: 10px;
    margin-top: 40px;
    text-align: center;
    width: 100%;
}

.unsubscribe-btn {
    color: #f7363e;
}

.view-dashboard {
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: rgba(67, 63, 60, 1);
    border: 1px solid rgb(67, 63, 60);
    border-radius: 4px;
    padding: 10px;
    color: white;
    text-transform: uppercase;
    font-size: 1em;
    font-family: "TungstenMedium" ,sans-serif;
    text-decoration: none;
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -ms-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms;
    &:hover {
        background-color: rgba(67, 63, 60, .9);
        text-decoration: none;
    }
}