@import '../../common/scss/variables';
@import '../../common/scss/mixins';

.badges-wrapper {
    height: calc( 100% - 1.1em );
}

.actions-badge {
    display: inline-block;
    padding: 15px 18px;
    border-radius: 4px;
    color: $font-color;
    font-weight: bold;
    font-size: 0.8em;
    cursor: pointer;
    margin-bottom: 8px;
    text-align: center;
    width: 100%;

    &.status-badge, &.detail-badge, &.resolution-badge {
        background-color: $main-color;

        &.active,&:hover {
            background-color: $dark-color;
            color: $white-color;
        }
    }

    &.detail-badge {
        padding: 11px 18px;
    }

    &.resolution-badge, &.fraud-badge {
        padding: 8px 18px;
    }

    &.resolution-badge {
        @include makeResolutionBadges();
    }

    &.fraud-badge {
        border: 1px solid $warning-color;

        &.active,&:hover {
            background-color: $warning-color;
            color: $white-color;
        }
    }
}