@import 'src/common/scss/variables';

.notification {
    margin: 4em 0;

    .notification-img {
        height: 13em;
        width: 13em;
        background: center/cover no-repeat;
        margin-bottom: 1em;
        margin-left: auto;
        margin-right: auto;
    }

    .notification-message {
        font-size: 2.2em;
        font-family: "TungstenMedium", sans-serif;
    }

    &.initial {
        color: $light-font-color;

        .notification-img {
            background-image: url(/assets/img/initial.svg);
        }
    }
    &.success {
        color: $light-font-color;

        .notification-img {
            background-image: url(/assets/img/success.svg);
        }
    }
    &.error {
        color: $warning-color;

        .notification-img {
            background-image: url(/assets/img/warning.svg);
        }
    }
}