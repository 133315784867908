@import '../../common/scss/variables';

#hello-message {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #090D25 url("/assets/img/tutorial/screen1.jpg") center no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  z-index: 100;
  overflow: hidden;
  .container {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    height: 100%;
    h2 {
      font-family: 'TungstenBook';
      font-size: 32px;
      color: #FFFFFF;
      text-align: center;
      line-height: 34px;
      margin-bottom: 30px;
    }
    img {
      display: block;
      width: 149px;
      margin-left: auto;
      margin-right: auto;
    }
    h3 {
      margin-top: 30px;
      margin-bottom: 20px;
      font-family: 'TungstenBook';
      font-size: 32px;
      color: #FFFFFF;
      text-align: center;
      line-height: 34px;
    }
    p {
      font-size: 16px;
      color: #FFFFFF;
      letter-spacing: 0;
      line-height: 21px;
      margin-bottom: 20px;
    }
    .next-button {
      width: 100px;
      height: 40px;
      background: #E6C956;
      border-radius: 2px;
      font-family: 'TungstenMedium';
      font-size: 21px;
      color: #433F3C;
      letter-spacing: 0.2px;
      text-align: center;
      display: inline-block;
      text-decoration: none;
      line-height: 40px;
    }
    .text-center {
      text-align: center;
      margin-top: 73px;
    }
  }
}

.app-wrapper {
  position: relative;
}

.step-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 100;
  left: 0;
  top: 0;
  .tip2 {
    width: 350px;
    height: 275px;
    position: absolute;
    background: url(/assets/img/tutorial/screen2.png) center no-repeat;
    font-size: 0;
    color: transparent;
    -webkit-background-size: contain;
    background-size: contain;
    right: 75px;
    top: 208px;
    a {
      width: 100px;
      height: 40px;
      display: block;
      position: absolute;
      cursor: pointer;
      bottom: 46px;
      left: 130px;
      font-size: 0;
      color: transparent;
      text-decoration: none;
    }
  }
  .tip3 {
    width: 278px;
    height: 205px;
    position: absolute;
    background: url(/assets/img/tutorial/screen3_tip.png) center no-repeat;
    font-size: 0;
    color: transparent;
    -webkit-background-size: contain;
    background-size: contain;
    right: 0;
    top: 148px;
    a {
      width: 100px;
      height: 40px;
      display: block;
      position: absolute;
      cursor: pointer;
      bottom: 54px;
      left: 95px;
      font-size: 0;
      color: transparent;
      text-decoration: none;
    }
  }
  .tip4 {
    width: 300px;
    height: 230px;
    position: absolute;
    background: url(/assets/img/tutorial/screen4_tip.png) center no-repeat;
    font-size: 0;
    color: transparent;
    -webkit-background-size: contain;
    background-size: contain;
    right: 0;
    top: 352px;
    a {
      width: 100px;
      height: 40px;
      display: block;
      position: absolute;
      cursor: pointer;
      bottom: 54px;
      left: 107px;
      font-size: 0;
      color: transparent;
      text-decoration: none;
    }
  }
  .tip5 {
    width: 245px;
    height: 180px;
    position: absolute;
    background: url(/assets/img/tutorial/screen5_tip.png) center no-repeat;
    font-size: 0;
    color: transparent;
    -webkit-background-size: contain;
    background-size: contain;
    right: 220px;
    bottom: -25px;
    a {
      width: 100px;
      height: 40px;
      display: block;
      position: absolute;
      cursor: pointer;
      bottom: 58px;
      left: 80px;
      font-size: 0;
      color: transparent;
      text-decoration: none;
    }
  }
  .tip6 {
    width: 275px;
    height: 175px;
    position: absolute;
    background: url(/assets/img/tutorial/screen6_tip.png) center no-repeat;
    font-size: 0;
    color: transparent;
    -webkit-background-size: contain;
    background-size: contain;
    right: 20px;
    top: 272px;
    a {
      width: 100px;
      height: 40px;
      display: block;
      position: absolute;
      cursor: pointer;
      bottom: 55px;
      left: 88px;
      font-size: 0;
      color: transparent;
      text-decoration: none;
    }
  }
  .tip7 {
    width: 275px;
    height: 265px;
    position: absolute;
    background: url(/assets/img/tutorial/screen7_tip.png) center no-repeat;
    font-size: 0;
    color: transparent;
    -webkit-background-size: contain;
    background-size: contain;
    right: 0;
    top: 60px;
    a {
      width: 100px;
      height: 40px;
      display: block;
      position: absolute;
      cursor: pointer;
      bottom: 58px;
      left: 88px;
      font-size: 0;
      color: transparent;
      text-decoration: none;
    }
  }
  .tip8 {
    width: 270px;
    height: 220px;
    position: absolute;
    background: url(/assets/img/tutorial/screen8_tip.png) center no-repeat;
    font-size: 0;
    color: transparent;
    -webkit-background-size: contain;
    background-size: contain;
    left: 0;
    top: 60px;
    a {
      width: 100px;
      height: 40px;
      display: block;
      position: absolute;
      cursor: pointer;
      bottom: 58px;
      left: 85px;
      font-size: 0;
      color: transparent;
      text-decoration: none;
    }
  }
}
.tip-description {
  width: 1080px;
  margin-top: -1.1em;
  background: #FFFFFF;
  padding-top: 29px;
  padding-bottom: 37px;
  &.nmt {
    margin-top: 0;
  }
  form {
    padding-top: 40px;
    max-width: 566px;
    margin-left: auto;
    margin-right: auto;
    p {
      margin-top: 10px;
      margin-bottom: 20px;
      padding-left: 0;
      padding-right: 0;
    }
    input[type="new-password"],
    input[type="password"] {
      width: 100%;
      height: 48px;
      background: #F4F6F8;
      border: 2px solid #C8C8C8;
      border-radius: 4px 0 0 4px;
      font-family: 'TungstenMedium';
      font-size: 26px;
      color: #7E7E7E;
      letter-spacing: 0;
      margin-bottom: 20px;
      padding-left: 10px;
      padding-right: 10px;
    }
    input[type="submit"] {
      width: 106px;
      height: 40px;
      background: #157CB7;
      border-radius: 2px;
      margin-top: 50px;
      border: none;
      font-family: 'TungstenMedium';
      font-size: 21px;
      color: #FFFFFF;
      letter-spacing: 0.2px;
      text-align: center;
      cursor: pointer;
      &:hover {
        cursor: pointer;
      }
      &[disabled] {
        opacity: 0.7;
        cursor: not-allowed;
      }
    }
  }
  .preload {
    margin-top: 47px;
    margin-bottom: 49px;
    img {
      width: 157px;
      height: 159px;
      display: block;
      margin: 0 auto;
    }
  }
  button.next {
    width: 106px;
    height: 40px;
    background: #157CB7;
    border-radius: 2px;
    margin-top: 50px;
    border: none;
    font-family: 'TungstenMedium';
    font-size: 21px;
    color: #FFFFFF;
    letter-spacing: 0.2px;
    text-align: center;
    cursor: pointer;
    &:hover {
      cursor: pointer;
    }
    &[disabled] {
      opacity: 0.7;
      cursor: not-allowed;
      margin-top: 120px;
    }
  }
  .box {
    max-width: 562px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 39px;
  }
  p {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
    color: #433F3C;
    letter-spacing: 0;
    line-height: 21px;
    margin-bottom: 12px;
    &.np {
      padding-left: 0;
      padding-right: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  h2 {
    font-family: 'TungstenMedium';
    font-size: 32px;
    color: #433F3C;
    letter-spacing: 0.3px;
    text-align: center;
    margin-bottom: 20px;
    font-weight: 400;
  }
  .text-center {
    text-align: center;
  }
  .images {
    width: 612px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    .left {
      width: 385px;
      margin-right: 30px;
    }
    .element {
      &:first-child {
        margin-bottom: 19px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    b {
      font-size: 12px;
      color: #433F3C;
      letter-spacing: 0;
      line-height: 21px;
      display: block;
      margin-bottom: 5px;
    }
    .right {
      flex: 1;
    }
  }
  .image-4 {
    width: 700px;
    margin-left: auto;
    margin-right: auto;
  }
  .image-7 {
    width: 680px;
    margin-left: auto;
    margin-right: auto;
  }
  .submit-entry {
    margin-top: 2em;
    margin-bottom: 2em;
  }
  img {
    max-width: 100%;
  }
}

section[hidden] {
  display: none !important;
}