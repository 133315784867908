@import "grid";
@import "variables";

@font-face {
  font-family: 'TungstenLight';
  src: url('/assets/fonts/Tungsten/Tungsten-Light.eot');
  src: url('/assets/fonts/Tungsten/Tungsten-Light.eot?#iefix') format('embedded-opentype'),
       url('/assets/fonts/Tungsten/Tungsten-Light.svg') format('svg'),
       url('/assets/fonts/Tungsten/Tungsten-Light.otf') format('opentype'),
       url('/assets/fonts/Tungsten/Tungsten-Light.ttf') format('truetype'),
       url('/assets/fonts/Tungsten/Tungsten-Light.woff') format('woff');
}

@font-face {
  font-family: 'TungstenMedium';
  src: url('/assets/fonts/Tungsten/Tungsten-Medium.eot');
  src: url('/assets/fonts/Tungsten/Tungsten-Medium.eot?#iefix') format('embedded-opentype'),
       url('/assets/fonts/Tungsten/Tungsten-Medium.svg') format('svg'),
       url('/assets/fonts/Tungsten/Tungsten-Medium.otf') format('opentype'),
       url('/assets/fonts/Tungsten/Tungsten-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'TungstenBook';
  src: url('/assets/fonts/Tungsten/Tungsten-Book.otf') format('opentype');
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $main-color;

  // normal font-size for all resolutions
  * {
    font-size: 1vw;

    @media screen and (max-width: #{map-get($breakpoints, 'xl')}px) {
      font-size: 1.6vw ;

      @media screen and (max-width: #{map-get($breakpoints, 'md')}px) {
        font-size: 2.5vw;

        @media screen and (orientation: portrait) {
          font-size: 1.5vh;
        }
      }
    }
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  }
}

.btn {
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
}

.form-input {
  width: 100%;
  margin-top: 1.2em;

  input[type="checkbox"] {
    margin: 0 5px;
  }
}

$input-size: 3.3rem;
$circle-size: $input-size * 0.45;
$cross-size: $input-size * 0.34;
$arrow-size: $input-size * 0.4;

.table-wrapper {
  overflow: auto;
}

.order-input-wrapper {
  position: relative;
  height: $input-size;
  border-radius: 4px;
  overflow: hidden;
  display: flex;


  &+ .order-input-wrapper {
    margin: 1em 0;
  }
  
  .sub-section-title {
    margin: auto !important;
    width: 15rem;
  }
  input {
    color: $font-color;
    font-family: 'TungstenBook', sans-serif;
    height: 100%;
    font-size: 2.2em;
    border: none;
    width: 100%;
    padding: 0.2rem #{$input-size*2} 0.2rem 0.9rem;
    background-color: $main-color;

    &.wo-confirm {
      padding: 0.2rem #{$input-size} 0.2rem 0.9rem;
    }

    &:focus {
      outline: none;
    }
  }

  .input-clear {
    width: $input-size;
    height: $input-size;
    right: $input-size;
    cursor: auto;

    &:after {
      cursor: pointer;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      height: $circle-size;
      width: $circle-size;
      border-radius: 50%;
      transform: translate(-50%,-50%);
      background: rgba(67, 57, 35, 0.25) url(/assets/img/clear.svg) center/$cross-size no-repeat;


    }
    &.disabled {
      &:after {
        opacity: 0.7;
        cursor: not-allowed;
      }
    }
  }

  input.wo-confirm + .input-clear {
    right: 0;
  }

  .input-clear, .input-enter {
    position: absolute;
    top: 0;
  }

  .input-enter {
    width: $input-size;
    height: $input-size;
    right: 0;
    background: $btn-background url(/assets/img/arrow.svg) center/$arrow-size no-repeat;

    &.disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }
}

.confirmation-wrapper {
  margin-top: 1em;
  text-align: center;

  .confirmation-text {
    color: $warning-color;
  }

  .confirmation-buttons-wrapper {
    margin-top: 1em;

    .confirmation-button {
      display: inline-block;
      border-radius: 5px;
      color: $dark-color;
      text-transform: uppercase;
      font-weight: bold;
      border: 1px solid $dark-color;
      padding: 13px 15px;
      margin: .5em 0 0;
      cursor: pointer;
      min-width: 21.5em;
      max-width: 100%;
    }
  }
}

.center {
  margin: 0 auto;
}

.block-wrapper {
  background-color: $white-color;
  text-align: left;
  border-radius: 8px;
  margin-top: 1.1em;
  padding: 2.5em 15px;

  &+.block-wrapper {
    margin-top: 1.1em;
  }
  
  .block-wrapper {
    padding: 1.1em 15px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 7px 0px;
  }
}

.sub-section-wrapper {
  .sub-section-title {
    font-size: 1.7em;
    color: $font-color;
    font-weight: normal;
    margin-bottom: 0.5em;
    font-family: "TungstenMedium", sans-serif;

    &.center {
      text-align: center;
    }
  }

  &+.sub-section-wrapper {
    margin-top: 2.1em;
  }
}

.animated {
  &.on-click {
    will-change: transform;
    transform: scale(1);

    &:active {
      transform: scale($click-scale-size);
      transition: transform .2s;
    }
  }
}

a.secondary-link {
  color: $secondary-color;
}