.loading-wrapper {
    position: relative;
    height: 100vh;
    width: 100vw;
    max-width: 100%;
    z-index: 999;

    &:after {
        position: absolute;
        content: '';
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        pointer-events: none;
        background: rgba(255,255,255,0.6) url(/assets/img/loading.svg) center/15vh no-repeat;
    }
}